.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#gjs .gjs-blocks-c{
  display: flex;
  flex-wrap: wrap;
  background-color: floralwhite;
  justify-content: flex-start;
}

#gjs .gjs-block {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  width: 45%;
  min-width: 45px;
  padding: 1em;
  box-sizing: border-box;
  min-height: 90px;
  cursor: all-scroll;
  background: white;
  color: #94baf7;
  font-size: 11px;
  font-weight: lighter;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 3px;
  margin: 10px 2.5% 5px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,.15);
  transition: all .2s ease 0s;
  transition-property: box-shadow, color;
}

.gjs-category-title, .gjs-layer-title, .gjs-block-category .gjs-title, .gjs-sm-sector-title, .gjs-trait-category .gjs-title {
  font-weight: 500;
  font-size: medium;
  background-color: white;
  letter-spacing: 1px;
  padding: 9px 10px 9px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, .25);
  text-align: left;
  position: relative;
  cursor: pointer;
  color:rgb(0, 0, 0);
}

.gjs-pn-buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #f6f9ff;
  color: #1f1f1f;
}

.gjs-four-color {
  color: #c0f8ff;
}

.gjs-pn-btn.gjs-pn-active {
  background-color: rgba(0, 0, 0, .15);
  box-shadow: 0 0 3px rgba(0,0,0,.25) inset;
  color:  rgb(127, 182, 253) !important;
}

.gjs-pn-btn {
  box-sizing: border-box;
  min-height: 30px;
  min-width: 30px;
  line-height: 21px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 18px;
  margin-right: 5px;
  border-radius: 2px;
  padding: 4px;
  position: relative;
  cursor: pointer;
}

#gjs {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.gjs-pn-views-container {
  height: 100%;
  padding: 42px 0 0;
  right: 0;
  width: var(--gjs-left-width);
  overflow: auto;
  background: #ffffff ;
  box-shadow: 0 0 5px var(--gjs-main-dark-color);
}

#gjs .gjs-one-bg {
  background-color: #fffcfd;
}

.gjs-traits-label {
  border-bottom: 1px solid var(--gjs-main-dark-color);
  font-weight: lighter;
  margin-bottom: 5px;
  padding: 10px;
  color: #3481FF !important;
  font-weight: bold;
  font-size: medium;
  text-align: left;
}

.gjs-trt-trait {
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
  font-weight: lighter;
  align-items: center;
  text-align: left;
  color: rgb(90, 89, 89);
  font-weight: 700;
  gap: 5px;
} 

.gjs-field input, .gjs-field select, .gjs-field textarea {
  appearance: none;
  color: inherit;
  border: none;
  background-color: rgb(223, 219, 219);
  box-sizing: border-box;
  color: #030303;
  width: 100%;
  position: relative;
  padding: var(--gjs-input-padding);
  z-index: 1;
}

.gjs-layer-vis {
  box-sizing: content-box;
  cursor: pointer;
  z-index: 1;
  color: #030303;
}

.gjs-layer-item-left, .gjs-layer-item-right {
  display: flex;
  align-items: center;
  gap: var(--gjs-flex-item-gap);
  color: #030303;
}

.gjs-sm-properties {
  font-size: var(--gjs-font-size);
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
  color: #000000;
}

#gjs-clm-add-tag, .gjs-clm-tags-btn {
  background-color: rgba(255, 255, 255, .15);
  border-radius: 2px;
  padding: 3px;
  margin-right: 3px;
  color: #3481FF  ;
  border: 1px solid rgb(0, 102, 255);
  width: 24px;
  height: 24px; 
  box-sizing: border-box;
  cursor: pointer;
}


#gjs element.style {
  color: #3481FF;
  font-weight: bold;
}

.div-style {
  height: 90vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
/* CSS */
#checkboxInput {
  display: none;
}
.bookmark {
  cursor: pointer;
  background-color: teal;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.svgIcon path {
  stroke-dasharray: 200 0;
  stroke-dashoffset: 0;
  stroke: white;
  fill: #dddddd00;
  transition-delay: 0s;
  transition-duration: 0.5s;
}

#checkboxInput:checked ~ .svgIcon path {
  fill: white;
  animation: bookmark 0.5s linear;
  transition-delay: 0.5s;
}

@keyframes bookmark {
  0% {
    stroke-dasharray: 0 200;
    stroke-dashoffset: 80;
  }
  100% {
    stroke-dasharray: 200 0;
  }
}

.custom{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.input-container {
  position: relative;
}
input {
  height: 48px;
  width: 280px;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
}
.label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  display: flex;
  align-items: center;
}
input, .label .text {
  font-family: 'Segoe UI';
  font-size: 16px;
}
input:focus {
  outline: none;
  border: 2px solid rgb(64, 97, 245);
}
input:focus + .label .text {
  font-size: 12px;
  transform: translate(0, -50%);
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
  color: #0d6efd;
}
.label .text {
  transition: all 0.15s ease-out;
}
input:focus + .label .text, :not(input[value=""]) + .label .text {
  font-size: 12px;
  transform: translate(0, -150%);
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
}
input:focus + .label .text {
  color: #0d6efd;
}
.row-container-text{
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
